<template>
  <div>
    <b-card-code title="Add Attrubites" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="12">

              <b-row>
                <!-- select permissions box -->
                <!-- <b-col cols="12" md="4">
                  <validation-provider #default="{ errors }" name="category" rules="required">
                    <b-form-group label="Select Categories" label-for="category"
                      :state="errors.length > 0 ? false : null">
                      <v-select v-model="selectedCategories" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text"
                        value="id"
                        :options="categories.map(category => ({ id: category.id, text: category.translation.name }))" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col> -->
                <!-- is variant -->
                <b-col cols="12" md="6" class="my-1">
                  <b-form-checkbox v-model="data.is_variant" id="checkbox-1" name="checkbox-1" :value="true"
                    :unchecked-value="false">
                    Is Variant</b-form-checkbox>
                </b-col>


              </b-row>

              <!-- Row Loop -->
              <b-row v-for="(item, index) in data.translations" :id="index" :key="index" ref="row">

                <!-- locale -->
                <b-col md="4">
                  <validation-provider #default="{ errors }" name="Locale" rules="required">
                    <b-form-group v-b-popover.hover.top="'Choose Attrubites Language'" label="Locale" label-for="locale"
                      :state="errors.length > 0 ? false : null">
                      <v-select :id="'locale' + index" v-model="localeSelect[index]"
                        :disabled="localeSelect[index].disa" :state="localeSelect === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="localeOptions" :selectable="(option) => !option.value.includes('select_value')
                          " label="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Name -->
                <b-col md="4">
                  <b-form-group label="Name" label-for="name">
                    <validation-provider #default="{ errors }" name="name" rules="required">
                      <b-form-input :id="'name' + index" v-model="data.translations[index].name"
                        :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Remove Button -->
                <!-- <b-col md="4" class="mb-50">
                  <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" v-show="!localeSelect[index].disa"
                    variant="outline-danger" class="mt-0 mt-md-2" @click="removeItem(index)">
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Remove</span>
                  </b-button>
                </b-col> -->
                <!-- <b-col cols="12">
                  <hr />
                </b-col> -->
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>

              <!-- Row Loop -->
              <b-row v-for="(item, index) in data.categories" :id="'category-' + index + 1"
                :key="'category-' + index + 1" ref="row">

                <!-- locale -->
                <b-col md="4">
                  <validation-provider #default="{ errors }" name="category" rules="required">
                    <b-form-group label="category" label-for="category">
                      <v-select v-model="selectedCategories[index]" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" value="id" :state="errors.length > 0 ? false : null"
                        :options="categories.map(category => ({ id: category.id, text: category.translation.name }))"
                        @input="updateSortValidation(index)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="4" v-if="data.is_variant">
                  <validation-provider #default="{ errors }" name="sort">
                    <b-form-group label="sort" label-for="sort" >
                      <b-form-input type="number" v-model="item.sort" @input="updateSortValidation(index)" :state="sortValidationErrors[index] ? false : null"/>
                      <small class="text-danger">{{ sortValidationErrors[index] }}</small>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <b-col md="12">
                  <b-form-group label="Add Name In Product Name ?" label-for="is_include_name">
                    <validation-provider #default="{ errors }" name="is_include_name">
                      <b-form-checkbox switch v-model="item.is_include_name" class="mt-1">
                        <p v-if="item.is_include_name">
                          Add Attribute Name In Product Name
                        </p>
                        <p v-else>Remove Attribute Name From Product Name</p>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>
                <!-- Remove Button -->
                <b-col md="4" class="mb-50">
                  <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" v-show="index >= 1" variant="outline-danger"
                    class="mt-0 mt-md-2" @click="removeCategory(index)">
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Remove</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
            </b-col>
            <!-- add new button -->
            <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="addCategory">
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add Category</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button size="lg" variant="primary" type="submit" @click.prevent="validationForm">
                Submit
                <b-spinner v-if="showSpinnerLoad" small />
                <span class="sr-only">Loading...</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import BCardCode from '@core/components/b-card-code'

import { heightTransition } from '@core/mixins/ui/transition'

import { required } from '@validations'


export default {
  components: {
    BCardCode,
  },
  watch: {
    selectedCategories: {
      deep: true,
      handler(newSelectedCategories, oldSelectedCategories) {
        console.log(newSelectedCategories)
        // Loop through the new selectedCategories array
        newSelectedCategories.forEach((selectedCategory, index) => {
          // Update the corresponding ID in data.categories[index].id
          this.$set(this.data.categories, index, { ...this.data.categories[index], id: selectedCategory.id });
        });
        // console.log(this.data.categories)
      },
    },
  },
  async created() {
    await this.getCategories()
  },

  mixins: [heightTransition],
  data() {
    return {
      sortValidationErrors: [],
      showSpinnerLoad: false,
      localeSelect: [
        {
          value: 'en',
          text: 'English',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
      ],
      localeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      categories: [],
      selectedCategories: [],
      data: {
        categories: [],
        translations: [
          {
            locale: '',
            name: '',
          },
          {
            locale: '',
            name: '',
          },
        ],
        is_variant: false,
      },
      errors_back: [],
      showDismissibleAlert: false,
      required,
    }
  },
  validations: {
    customRule: { // Registering the custom rule
      customRule: {
        getMessage: () => 'Sort value already exists in the selected category'
      }
    }
  },
  methods: {
    updateSortValidation(index) {
      if (this.selectedCategories[index] && this.data.categories[index].sort != null) {
        const selectedCategoryId = this.selectedCategories[index].id;
        const selectedSort = Number(this.data.categories[index].sort);
        const category = this.categories.find(cat => cat.id === selectedCategoryId);
        const otherSorts = this.data.categories.filter((cat, idx) => idx !== index).map(cat => Number(cat.sort));



        if(otherSorts.includes(selectedSort)){
          this.$set(this.sortValidationErrors, index, 'Sort value already exists in the selected category');

        }
        else if (category && category.attributes_sort.includes(selectedSort)) {
          this.$set(this.sortValidationErrors, index, 'Sort value already exists in the selected category');
        } else {
          this.$set(this.sortValidationErrors, index, null); // Clear the error message if no validation error
        }
      }

    },
    // get all categories data to make assign 
    async getCategories() {

      await axios
        .get('categories')
        .then((result) => {

          this.categories = result.data.data
          // console.log(this.categories)

        })
        .catch((err) => {

          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })

    },
    // submit attribute API
    validationForm() {
      // this.data.categories = this.selectedCategories.map(category => category.value);

      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          for (let index in this.localeSelect) {
            this.data.translations[index].locale =
              this.localeSelect[index].value
          }
          axios
            .post('attributes', this.data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push({
                path: '/Attributes/Index',
              })
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // REPETED FORM
    repeateAgain() {
      this.data.translations.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.localeSelect.push({})
    },
    addCategory() {
      this.data.categories.push(
        {
          id: null,
          sort: null,
          is_include_name: false,
        }
      )

    },
    // remove function to delete single translation from category
    removeItem(index) {
      if (this.data.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.data.translations.splice(index, 1)
        this.localeSelect.splice(index, 1)
      }
    },
    removeCategory(index) {
      if (this.data.categories.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.data.categories.splice(index, 1)
      }
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
​
<style lang="scss"></style>
​
<style lang="scss" scoped>
// REPETED FORM
.repeater-form {
  transition: 0.35s height;
}
</style>